var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('data-table',{attrs:{"headers":_vm.headers,"server-pagination":"","endpoint":"/Issue/GetRejectedCompanies","hide-default-footer":"","search":_vm.search},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/register-issue/" + (item.id))}},[_vm._v(_vm._s(item.name))])]}},{key:"item.reRegistration",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.reRegistration ? _vm.$t('common.yes') : '')+" ")]}},{key:"item.dateOfDecline",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.dateOfDecline))+" ")]}},{key:"item.assignedUser",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.assignedUser.name)+" "+_vm._s(item.assignedUser.surname)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[(_vm.$permission('IssueTakeover'))?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.handleIssueClick(item)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('companies.issue'))+" ")])],1):_vm._e()],1)],1)]}}],null,true)},[_vm._v("9 ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }