<template>
  <div>
    <data-table
      ref="dataTable"
      server-pagination
      endpoint="Issue/GetWaitingRegisterCompanyIssues"
      :headers="headers"
      single-expand
      show-expand
      hide-default-footer
      :mapping-function="mappingFunction"
      :search="search"
    >
      <template v-slot:item.companyName="{ item }">
        <router-link :to="`/register-issue/${item.id}`">{{
          item.companyName
        }}</router-link>
      </template>
      <template v-slot:item.reRegistration="{ item }">
        {{
          item.reRegistration ? $t('common.yes') : ''
        }}
      </template>
        <template v-slot:[`item.salesman`]="{ item }">
          <div v-if="item.salesman">
            {{ item.salesman.name }} {{ item.salesman.surname }}
          </div>
        </template>
      <template v-slot:[`item.requiredDocumentsAttached`]="{ item }">
        {{
          item.requiredDocumentsAttached ? $t('common.yes') : $t('common.no')
        }}
      </template>
      <template v-slot:[`item.dateOfRegistration`]="{ item }">
        {{ item.dateOfRegistration | dateFormat }}
      </template>
      <template v-slot:[`item.issueState`]="{ item }">
        {{ getState(item.issueState) }}
      </template>
      <template v-slot:[`item.currentlyHandledBy`]="{ item }">
        <div>
          <v-select
            v-if="usersToAssign.length > 1 && $permission('AssignIssueToUser')"
            class="mt-n1"
            :items="usersToAssign"
            :placeholder="$t('companies.assign')"
            item-value="id"
            item-text="name"
            dense
            hide-details
            @change="selectUser(item.id, $event)"
          ></v-select>
          <v-btn
            v-else-if="$permission('IssueTakeover')"
            color="primary"
            @click="selectUser(item.id, $store.getters.userCommonData.id)"
          >
            {{ $t('companies.assignToMe') }}
          </v-btn>
        </div>
      </template>
      <!-- <template v-slot:item.actions="{item}">
        <v-btn icon>
          <v-icon @click="$router.push({ name: 'CompaniesDetailsCustomerService', params: { id: item.companyId }  })">mdi-dots-vertical</v-icon>
        </v-btn>
      </template> -->
      <template v-slot:item.actions="{ item }">
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item link @click="handleCompanyClick(item)">
              <v-list-item-title
                >{{ $t('users.goToProfile') }}</v-list-item-title
              >
            </v-list-item>
            <v-list-item v-if="$permission('IssueTakeover') && $permission('ViewAllIssues')" link @click="handleIssueClick(item)">
              <v-list-item-title>
                {{ $t('companies.issue') }}
              </v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td class="pa-6" :colspan="headers.length">
          <h4>{{ $t('companies.issueState') }}</h4>
          <v-data-table
            hide-default-footer
            :headers="detailsHeaders"
            :items="item.assignmentHistory"
          >
            <template v-slot:item.department="{ item }">
              {{ getDepartment(item.department) }}
            </template>
            <template v-slot:item.dateOfAssignment="{ item }">
              {{ item.dateOfAssignment | dateFormat }}
            </template>
            <template v-slot:item.assignedTo="{ item }">
              {{ item.user.name }} {{ item.user.surname }}
            </template>
          </v-data-table>
        </td>
      </template>
    </data-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Service from '@/services'
import { getEnumDescription } from '@/utils/helpers'

export default {
  name: 'WaitingCompaniesTable',
  props: {
    usersToAssign: {
      type: Array,
      required: true
    },
    search: String
  },
  computed: {
    ...mapGetters({
      enums: 'enums',
      users: 'users/users'
    })
  },
  data () {
    return {
      headers: [
        {
          text: this.$t('companies.companyName'),
          value: 'companyName'
        },
        {
          text: this.$t('companies.companyId'),
          value: 'companyDisplayId'
        },
        {
          text: this.$t('companies.vatNumber'),
          value: 'vatNumber'
        },
        {
          text: this.$t('companies.reRegistration'),
          value: 'reRegistration'
        },
        {
          text: this.$t('companies.issueId'),
          value: 'issueDisplayId'
        },
        {
          text: this.$t('companies.requiredDocuments'),
          value: 'requiredDocumentsAttached'
        },
        {
          text: this.$t('companies.dateOfRegistration'),
          value: 'dateOfRegistration'
        },
        {
          text: this.$t('companies.issueState'),
          value: 'issueState'
        },
        {
          text: this.$t('companies.assignedTo'),
          value: 'currentlyHandledBy',
          width: 200
        },
        {
          text: this.$t('companies.salesman'),
          align: 'center',
          value: 'salesman'
        },
        {
          text: '',
          value: 'actions',
          align: 'end',
          width: '75',
          sortable: false
        }
      ],
      detailsHeaders: [
        {
          text: this.$t('companies.department'),
          value: 'department',
          width: '15%'
        },
        {
          text: this.$t('companies.dateOfAssignment'),
          value: 'dateOfAssignment',
          width: '15%'
        },
        {
          text: this.$t('companies.assignedTo'),
          value: 'assignedTo',
          width: '20%'
        },
        {
          text: '',
          value: '',
          width: '55%',
          sortable: false
        }
      ]
    }
  },
  methods: {
    handleIssueClick (value) {
      const routeData = this.$router.resolve({ name: 'RegisterIssueCustomerService', params: { id: value.id } })
      window.open(routeData.href, '_blank')
    },
    handleCompanyClick (value) {
      const routeData = this.$router.resolve({ name: 'CompaniesDetailsCustomerService', params: { id: value.companyId } })
      window.open(routeData.href, '_blank')
    },
    async selectUser (issueId, userId) {
      await Service.post('/Issue/AssignIssue', { issueId, UserId: userId })
      await this.$refs.dataTable.getDataDebounce(10)
    },
    getState (value) {
      return getEnumDescription(this.enums.issueState, value)
    },
    getDepartment (value) {
      return getEnumDescription(this.enums.departmentTypes, value)
    },
    mappingFunction (result) {
      return result.map((company) => {
        company.assignmentHistory = company.assignmentHistory.map(
          (historyItem) => {
            const historyItemUser = this.users.find(
              (user) => user.id === historyItem.assignedTo
            )
            if (historyItemUser) {
              return {
                ...historyItem,
                user: {
                  ...historyItemUser
                }
              }
            } else {
              return historyItem
            }
          }
        )

        return company
      })
    }
  }
}
</script>
