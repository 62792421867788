var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('data-table',{attrs:{"headers":_vm.headers,"show-expand":"","hide-default-footer":"","single-expand":"","server-pagination":"","endpoint":"Issue/GetCurrentRegisterCompanyIssues","search":_vm.search},scopedSlots:_vm._u([{key:"item.companyName",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/register-issue/" + (item.id))}},[_vm._v(_vm._s(item.companyName))])]}},{key:"item.reRegistration",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.reRegistration ? _vm.$t('common.yes') : '')+" ")]}},{key:"item.salesman",fn:function(ref){
var item = ref.item;
return [(item.salesman)?_c('div',[_vm._v(" "+_vm._s(item.salesman.name)+" "+_vm._s(item.salesman.surname)+" ")]):_vm._e()]}},{key:"item.requiredDocumentsAttached",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.requiredDocumentsAttached ? _vm.$t('common.yes') : _vm.$t('common.no'))+" ")]}},{key:"item.dateOfRegistration",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.dateOfRegistration))+" ")]}},{key:"item.issueState",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getState(item.issueState))+" ")]}},{key:"item.currentlyHandledBy",fn:function(ref){
var item = ref.item;
return [(item.currentlyHandledBy)?_c('div',[_vm._v(_vm._s(item.currentlyHandledBy.name)+" "+_vm._s(item.currentlyHandledBy.surname))]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.handleCompanyClick(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('users.goToProfile')))])],1),(_vm.$permission('IssueTakeover'))?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.handleIssueClick(item)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('companies.issue'))+" ")])],1):_vm._e()],1)],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-6",attrs:{"colspan":headers.length}},[_c('h4',[_vm._v(_vm._s(_vm.$t('companies.issueState')))]),_c('v-data-table',{attrs:{"headers":_vm.detailsHeaders,"items":item.assignmentHistory,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.department",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDepartment(item.department))+" ")]}},{key:"item.dateOfAssignment",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.dateOfAssignment))+" ")]}},{key:"item.assignedTo",fn:function(ref){
var item = ref.item;
return [(item.assignedTo)?_c('div',[_vm._v(" "+_vm._s(item.assignedTo.name)+" "+_vm._s(item.assignedTo.surname)+" ")]):_vm._e()]}}],null,true)})],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }