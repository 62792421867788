var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('data-table',{ref:"dataTable",attrs:{"server-pagination":"","endpoint":"Issue/GetWaitingRegisterCompanyIssues","headers":_vm.headers,"single-expand":"","show-expand":"","hide-default-footer":"","mapping-function":_vm.mappingFunction,"search":_vm.search},scopedSlots:_vm._u([{key:"item.companyName",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/register-issue/" + (item.id))}},[_vm._v(_vm._s(item.companyName))])]}},{key:"item.reRegistration",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.reRegistration ? _vm.$t('common.yes') : '')+" ")]}},{key:"item.salesman",fn:function(ref){
var item = ref.item;
return [(item.salesman)?_c('div',[_vm._v(" "+_vm._s(item.salesman.name)+" "+_vm._s(item.salesman.surname)+" ")]):_vm._e()]}},{key:"item.requiredDocumentsAttached",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.requiredDocumentsAttached ? _vm.$t('common.yes') : _vm.$t('common.no'))+" ")]}},{key:"item.dateOfRegistration",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.dateOfRegistration))+" ")]}},{key:"item.issueState",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getState(item.issueState))+" ")]}},{key:"item.currentlyHandledBy",fn:function(ref){
var item = ref.item;
return [_c('div',[(_vm.usersToAssign.length > 1 && _vm.$permission('AssignIssueToUser'))?_c('v-select',{staticClass:"mt-n1",attrs:{"items":_vm.usersToAssign,"placeholder":_vm.$t('companies.assign'),"item-value":"id","item-text":"name","dense":"","hide-details":""},on:{"change":function($event){return _vm.selectUser(item.id, $event)}}}):(_vm.$permission('IssueTakeover'))?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.selectUser(item.id, _vm.$store.getters.userCommonData.id)}}},[_vm._v(" "+_vm._s(_vm.$t('companies.assignToMe'))+" ")]):_vm._e()],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.handleCompanyClick(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('users.goToProfile')))])],1),(_vm.$permission('IssueTakeover') && _vm.$permission('ViewAllIssues'))?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.handleIssueClick(item)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('companies.issue'))+" ")])],1):_vm._e()],1)],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-6",attrs:{"colspan":headers.length}},[_c('h4',[_vm._v(_vm._s(_vm.$t('companies.issueState')))]),_c('v-data-table',{attrs:{"hide-default-footer":"","headers":_vm.detailsHeaders,"items":item.assignmentHistory},scopedSlots:_vm._u([{key:"item.department",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDepartment(item.department))+" ")]}},{key:"item.dateOfAssignment",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.dateOfAssignment))+" ")]}},{key:"item.assignedTo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.name)+" "+_vm._s(item.user.surname)+" ")]}}],null,true)})],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }